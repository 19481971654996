import AppEntryLayout from "../components/AppEntryLayout";
import { useEffect } from "react";
import router from "next/router";
import { SERVICE_NAME } from "../const/types";
// eslint-disable-next-line
import { Analytics } from "@vercel/analytics/react";

export default function HomePage() {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.push("/login");
  }, []);
  return (
    <>
      <AppEntryLayout title={`CertiK ${SERVICE_NAME}`} />
      <Analytics />
    </>
  );
}
